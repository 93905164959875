import React, { LegacyRef, useState } from 'react';
import cn from 'classnames';
import Carousel from 'react-multi-carousel';
import { usePopper } from 'react-popper';
import { FloatingDisclaimer, SvgIcon } from '@ui-elements';
import { AccessoryPack } from '~/common/models';
import { useIsMobile } from '~/common/hooks/use-is-mobile';
import { AccessoryPackItem } from '~/pages/model-accessories/components/accessory-packs-card';
import styles from './index.module.scss';

interface ModelBundlesProps {
  contentId: string;
  heading: string;
  tooltipDisclaimer?: string;
  subHeading: string;
  accessoryBundles: AccessoryPack[];
  modelName: string;
}

export const ModelBundles = (props: ModelBundlesProps) => {
  const { isDesktop } = useIsMobile();
  const itemsCount = props.accessoryBundles?.length ?? 3;
  const centerMode = React.useMemo(() => itemsCount < 3, []);

  return (
    <div id={`cid-${props.contentId}`} className={styles.accessoryBundles}>
      <div className={styles.header}>
        <FloatingDisclaimer
          className={styles.heading}
          disclaimer={props.tooltipDisclaimer}
        >
          {({ renderTrigger }) => (
            <>
              <div dangerouslySetInnerHTML={{ __html: props.heading }} />
              {renderTrigger()}
            </>
          )}
        </FloatingDisclaimer>

        <div
          className={styles.subheading}
          dangerouslySetInnerHTML={{ __html: props.subHeading }}
        />
      </div>

      <div className={styles.carouselWrapper}>
        <Carousel
          swipeable
          arrows={false}
          renderButtonGroupOutside
          customButtonGroup={<ButtonGroup />}
          centerMode={isDesktop() ? centerMode : false}
          className={styles.bundleCarousel}
          containerClass={cn(
            styles.containerClass,
            centerMode && styles.centerMode
          )}
          responsive={{
            desktop: {
              breakpoint: { max: 4000, min: 1024 },
              items: centerMode ? itemsCount : 3,
              slidesToSlide: 3,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 1,
            },
          }}
        >
          {props.accessoryBundles.map((accBundle) => (
            <AccessoryPackItem
              key={accBundle.pimIdentifier}
              modelName={props.modelName}
              backgroundColor="#f1f1f1"
              {...accBundle}
            />
          ))}
        </Carousel>
      </div>

      <div className={styles.mobileBundles}>
        {props.accessoryBundles.map((accBundle) => (
          <AccessoryPackItem
            key={accBundle.pimIdentifier}
            backgroundColor="#f1f1f1"
            {...accBundle}
          />
        ))}
      </div>
    </div>
  );
};

interface ButtonGroupProps {
  next?: () => void;
  previous?: () => void;
  carouselState?: {
    currentSlide: number;
    totalItems: number;
    slidesToShow: number;
  };
}
const ButtonGroup = ({ next, previous, carouselState }: ButtonGroupProps) => {
  const totalItems = carouselState?.totalItems || 0;
  const slidesToShow = carouselState?.slidesToShow || 0;

  const currentSlide = React.useMemo(
    () => carouselState?.currentSlide || 0,
    [carouselState]
  );

  const lastSlide = React.useMemo(() => {
    return Math.floor(totalItems / slidesToShow);
  }, [carouselState]);

  return totalItems > slidesToShow ? (
    <div className={styles.carouselButtonGroup}>
      <SvgIcon
        className={cn(
          styles.customArrow,
          styles.customLeftArrow,
          currentSlide === 0 && styles.arrowDisabled
        )}
        type="chevronLeft"
        size={2}
        onClick={currentSlide === 0 ? undefined : previous}
      />

      <SvgIcon
        className={cn(
          styles.customArrow,
          styles.customRightArrow,
          currentSlide === lastSlide && styles.arrowDisabled
        )}
        type="chevronRight"
        size={2}
        onClick={currentSlide === lastSlide ? undefined : next}
      />
    </div>
  ) : null;
};
