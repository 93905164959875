import { atom, selectorFamily } from 'recoil';
import { Accessory, AccessoryPack, Breadcrumb } from '~/common/models';
import { getAccessoryByPimId } from '~/common/services/accessories-service';

interface ModelAccessoriesMainPage {
  heading: string;
  subHeading: string;
  parentUrlSegment: string;
  accessoryPackTitleOverride?: string;
  pimIdentifier: string;
  modelName: string;
  modelBadgeUrl: string;
  accessory?: Accessory;
  accessoryPack?: AccessoryPack;
  breadcrumbs: Breadcrumb[];
  routeModelName: string;
}

export const ModelAccessoriesPage = atom<ModelAccessoriesMainPage>({
  key: 'ModelAccessoriesPage',
  default: {
    heading: '',
    subHeading: '',
    parentUrlSegment: '',
    pimIdentifier: '',
    modelName: '',
    modelBadgeUrl: '',
    breadcrumbs: [],
    routeModelName: '',
  },
});

export const ModelAccessories = atom<Accessory[]>({
  key: 'ModelAccessories',
  default: [],
});

export const ModelAccessoryPacks = atom<AccessoryPack[]>({
  key: 'ModelAccessoryPacks',
  default: [],
});

export const ModelAccessoryPacksQuery = selectorFamily<AccessoryPack[], string>(
  {
    key: 'ModelAccessoryPacksQuery',
    get: (pimId) => async () => {
      const res = await fetch(
        `/api/accessories/accessory-packs/model/${pimId}`
      );
      return (await res.json()) as AccessoryPack[];
    },
  }
);

export const ModelAccessoriesQuery = selectorFamily<Accessory[], string>({
  key: 'ModelAccessoriesQuery',
  get: (pimId) => async () => {
    const res = await fetch(`/api/accessories/accessories/model/${pimId}`);
    return (await res.json()) as Accessory[];
  },
});

export const TopAccessoriesQuery = selectorFamily<Accessory[], string[]>({
  key: 'TopAccessoriesQuery',
  get: (pimIds: string[]) => async () => {
    const accessoriesPromise: Promise<Accessory>[] = [];

    pimIds.forEach((pimId) => {
      if (!!pimId) {
        accessoriesPromise.push(getAccessoryByPimId(pimId));
      }
    });

    return await Promise.all(accessoriesPromise);
  },
});

export const ModelAccessoryActiveRoute = atom<string>({
  key: 'ModelAccessoryActiveRoute',
  default: '',
});

export const AccessoryRouteBreadcrumbs = atom<Breadcrumb[]>({
  key: 'AccessoryRouteBreadcrumbs',
  default: [],
});
