import React from 'react';
import { Card, Button } from '@ui-elements';
import { money, toValidUri } from 'common/utils/formatters';
import { useRecoilState } from 'recoil';
import { ModelAccessoriesPage } from '../../store';
import { AccessoryPack } from '~/common/models';
import styles from './index.module.scss';
import classNames from 'classnames';

interface Props extends AccessoryPack {
  backgroundColor?: string;
}

export const AccessoryPackCard = React.memo<Props>(
  ({ backgroundColor, ...prop }) => {
    const [{ parentUrlSegment }] = useRecoilState(ModelAccessoriesPage);

    const formatAccessoryName = (name: string) => {
      return name.replace(/\s+/g, '-').toLowerCase();
    };

    const getUri = React.useCallback(() => {
      if (!!prop.description) {
        const formattedAccName = formatAccessoryName(prop.description);
        const pathnames = window.location.pathname.split('/');

        if (pathnames.includes('accessory-packs')) {
          return `/${pathnames[1]}/${pathnames[2]}/${
            prop.itemCode
          }/${toValidUri(formattedAccName)}`;
        }

        return `/${pathnames[1]}/accessory-packs/${prop.itemCode}/${toValidUri(
          formattedAccName
        )}`;
      }
      return '#';
    }, [prop.description]);

    return (
      <div className={styles.accessoryPack}>
        <Card
          className={styles.accessoryPackCard}
          contentClassName={styles.accessoryPackCardContent}
        >
          <div className={styles.imageWrapper}>
            <img src={prop.imageSrc} alt={prop.pimIdentifier} />
          </div>
          <div className={styles.detailsWrapper} style={{ backgroundColor }}>
            <div
              className={classNames(
                prop.accessories.length === 0 && styles.fullWidth
              )}
            >
              <p className={styles.name}>{prop.description}</p>
              <p className={styles.price}>
                Fitted RRP: {money(prop.specialPriceFitted)}
              </p>
              <Button buttonSize="small" href={getUri()} target="_blank">
                More info
              </Button>
            </div>
            {prop.accessories.length > 0 && (
              <div>
                <ul className={styles.accessories}>
                  {prop.accessories?.map((acc) => (
                    <li key={acc.id}>{acc.description}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Card>
      </div>
    );
  }
);

interface AccessoryPackItemProps extends AccessoryPack {
  backgroundColor?: string;
  modelName?: string;
}

export const AccessoryPackItem = (props: AccessoryPackItemProps) => {
  const formatAccessoryName = (name: string) => {
    return name.replace(/\s+/g, '-').toLowerCase();
  };

  const truncateSuperscript = (description: string) => {
    return description.replace(/[⁰¹²³⁴⁵⁶⁷⁸⁹]/g, '');
  };

  const getUri = React.useCallback(() => {
    if (!!props.description && typeof window !== 'undefined') {
      const trimmedAccName = truncateSuperscript(props.description);
      const formattedAccName = formatAccessoryName(trimmedAccName);
      const pathnames = window.location.pathname.split('/');

      if (pathnames.includes('accessory-packs')) {
        return `/${pathnames[1]}/${pathnames[2]}/${props.itemCode}/${toValidUri(
          formattedAccName
        )}`;
      }

      return `/${props.modelName || pathnames[1]}/accessory-packs/${
        props.itemCode
      }/${toValidUri(formattedAccName)}`;
    }
    return '#';
  }, [props.description]);

  return (
    <div className={styles.itemWrapper}>
      <div>
        <img src={props.imageSrc} alt={props.description} />
      </div>

      <div className={styles.itemContent}>
        <div>
          <div>
            <p>{props.description}</p>
            <p>{`Fitted RRP: ${money(props.specialPriceFitted)}`}</p>
          </div>
          <Button
            buttonSize="small"
            type="primary"
            href={getUri()}
            target="_blank"
          >
            More info
          </Button>
        </div>
        <div>
          <ul>
            {props.accessories.map((acc) => (
              <li key={acc.id}>{acc.description}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
