import { Accessory, AccessoryPack } from '~/common/models';

export const getAccessoryByPimId = (
  pimIdentifier: string
): Promise<Accessory> =>
  fetch(`/api/accessories/accessories/pim/${pimIdentifier}`)
    .then((res) => res.json())
    .then((res) => res as Accessory);

export const getAccessoryPackByPimId = (
  pimIdentifier: string
): Promise<AccessoryPack> =>
  fetch(`/api/accessories/accessory-packs/pim/${pimIdentifier}`)
    .then((res) => res.json())
    .then((res) => res as AccessoryPack);
